import React, { useEffect } from 'react';
import styles from './styles.module.css';

export const Stoimost = () => {
  return (
    <div>
      <main>
        <div className={styles.prv__pol}>
          <p class="prv__text">Стоимость разработки определяется индивидуально и зависит от множества факторов. Вот основные из них:</p>
          <h2 class="prv-sub__title">1. Сложность и масштаб проекта</h2>
          <p class="prv__text">Функциональность: Чем больше функций и сложнее логика, тем больше времени и вложений потребует разработка.</p>
          <p class="prv__text">Интеграции: Подключение к сторонним API или сервисам влияет на ценообразование.</p>
          <p class="prv__text">Скорость работы и производительность: Оптимизация для быстродействия требует времени.</p>
          <p class="prv__text">Масштабируемость: Планирование для будущего роста продукта также влияет на ценообразование.</p>

          <h2 class="prv-sub__title">2. Выбор технологии</h2>
          <p class="prv__text">Язык программирования и стек технологий: Некоторые языки требуют узкоспециализированных разработчиков, что может быть дороже.</p>
          <p class="prv__text">Платформы: Разработка для Web, iOS, Android или кросс-платформенная влияет на бюджет.</p>
          <p class="prv__text">Сложные технологии: Использование AI, Big Data, AR/VR, блокчейна влияет на конечную стоимость работ.</p>

          <h2 class="prv-sub__title">3. Команда и её опыт</h2>
          <p class="prv__text">Квалификация специалистов: Опытные разработчики, дизайнеры и менеджеры берут более высокую оплату.</p>
          <p class="prv__text">Размер команды: Большее количество участников увеличивает затраты.</p>

          <h2 class="prv-sub__title">4. Дизайн и пользовательский опыт</h2>
          <p class="prv__text">Уровень детализации UI/UX: Чем сложнее и уникальнее интерфейс, тем выше стоимость.</p>

          <h2 class="prv-sub__title">5. Сроки разработки</h2>
          <p class="prv__text">Ускоренный срок: Если требуется сделать быстро, это увеличивает расходы за счёт переработок и большего количества часов.</p>
          <p class="prv__text">Гибкость сроков: Возможность распределить нагрузку на более длительный период снижает затраты.</p>

          <h2 class="prv-sub__title">6. Дополнительные услуги</h2>
          <p class="prv__text">Хостинг и инфраструктура: Настройка серверов, баз данных, работа в облаке (AWS, Azure и пр.).</p>
          <p class="prv__text">Сопровождение: Поддержка продукта после релиза.</p>
          <p class="prv__text">Обучение пользователей: Включение обучающих материалов и обучения сотрудников клиента.</p>

          <h2 class="prv-sub__title">7. Лицензии и инструменты</h2>
          <p class="prv__text">Использование платных инструментов: Например, CMS, библиотеки, SDK, аналитические системы.</p>
          <p class="prv__text">Сертификаты: Если требуется сертификация (например, в медицинской или финансовой сфере), это также влияет на ценообразование.</p>
        </div>
      </main >
    </div >
  );
};
