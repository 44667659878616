import React, { useState, useRef } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { NameInput, ContactInput, AboutInput } from './components';
import emailjs from '@emailjs/browser';
import { Link, useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import styles from './styles.module.scss';

export const OrderForm = ({ type, forContactsFrame, closeModal }) => {
  const { t } = useTranslation();
  const [stateForm, setStateForm] = useState({
    name: '',
    contact: '',
    about: '',
  });
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const formRef = useRef();
  const navigate = useNavigate();

  const onNameChange = (value) => {
    setStateForm((prev) => ({
      ...prev,
      name: value,
    }));
  };

  const onContactChange = (value) => {
    setStateForm((prev) => ({
      ...prev,
      contact: value,
    }));
  };

  const onAboutChange = (value) => {
    setStateForm((prev) => ({
      ...prev,
      about: value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      alert('Пожалуйста, пройдите проверку reCAPTCHA.');
      return;
    }

    emailjs.sendForm('service_ob6sh4v', 'template_jy28x9c', formRef.current, 'pa--fc0JMJ6lyV1QW')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };

  const onRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const onPrivacyPolicyClick = (e) => {
    e.preventDefault();
    if (closeModal) {
      closeModal();
    }
    navigate('/term-of-use');
  };

  return (
    <form
      ref={formRef}
      onSubmit={onSubmit}
      className={cn(styles.orderForm, {
        [styles.orderFormForContactFrame]: forContactsFrame,
      })}
    >
      {!forContactsFrame && (
        <h2 className={styles.orderForm_title}>{t(`${type}.title`)}</h2>
      )}
      <p
        className={cn(styles.orderForm_description, {
          [styles.orderForm_descriptionOrange]: forContactsFrame,
        })}
      >
        {t(`${type}.description`)}
      </p>
      <NameInput
        value={stateForm.name}
        change={onNameChange}
        name="name"
        placeholderText={t(`${type}.placeholderName`)}
      />
      <ContactInput
        value={stateForm.contact}
        change={onContactChange}
        name="contact"
        placeholderText={t(`${type}.placeholderContact`)}
      />
      <AboutInput
        value={stateForm.about}
        change={onAboutChange}
        name="about"
        className={styles.aboutInput}
        placeholderText={t(`${type}.placeholderProject`)}
      />
      <ReCAPTCHA sitekey='6LeFi7oqAAAAAAscCitfY0iGCBJY2CTaBymfhfmC' onChange={(val) => onRecaptchaChange(val)} />
      <p className={styles.orderForm_agreement}>{t(`${type}.agreement`)}, <Link className={styles.orderForm_termOfUse} to="/term-of-use" onClick={onPrivacyPolicyClick}>пользовательское соглашение</Link></p>
      <button className={styles.orderForm_submitBtn} type="submit">
        {t(`${type}.submitBtn`)}
      </button>
    </form>
  );
};
