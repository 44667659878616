import React from 'react';
import cn from 'classnames';
import logo from '../../assets/logo.svg';
import { Link, useLocation } from 'react-router-dom';
import { checkFooterHidden } from './Footer.helpers';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export const Footer = () => {
  const { pathname } = useLocation();
  const isFooterHidden = checkFooterHidden(pathname);
  const { t } = useTranslation();

  if (isFooterHidden) {
    return null;
  }

  const foot = {
    marginTop: "20px !important"
  };

  return (
    <div className={styles.footerWrapper} >
      <footer className={styles.footer}>
        <Link className={styles.footer_toMainBtn} to="/">
          <img
            className={styles.footer_logo}
            src={logo}
            alt="EVASOFT"
            width={121}
            height={24}
          />
        </Link>

        <Link className={styles.footer_contact} to="/privacy-policy">Пoлитикa кoнфидeнциaльнocти</Link>
        <Link className={styles.footer_contact} to="/cookie">Использование cookies</Link>
        <Link className={styles.footer_contact} to="/term-of-use">Пользовательское соглашение</Link>
        <a className={styles.footer_contact} href="mailto:info@evasoft.org">
          info@evasoft.org
        </a>
        <div className={styles.footer_cont}>
          <p className={styles.footer_address} style={foot}>ИНН/КПП 5032386275/503201001</p>
          <p className={styles.footer_address}>ОГРН 1245000140549 от 12 декабря 2024 г.</p>
          <p className={styles.footer_address}>143007, Московская область, Одинцовский район, ГП Одинцово, г. Одинцово, ул. Молодежная, д.46, помещение 21, офис 221</p>
          <p className={styles.footer_address}>  <a
            href="tel:+79203364985"
          >
            +79203364985
          </a></p>
        </div>
        <p className={styles.footer_copyright}>{t('contacts.copyright')}</p>
      </footer>
    </div >
  );
};
