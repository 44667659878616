import {
  Header,
  Footer,
  Main,
  Technology,
  Contacts,
  About,
  Projects,
  Vacancy,
  Casino,
  Bison,
  SportBet,
  Fincrm,
  Ostapilet,
  Finbet,
  Bfc,
  Dota,
  Alfa,
  PrivacyPolicy,
  Cookie,
  TermOfUse,
  Stoimost,
} from './modules';

import { AppContainer } from './AppContainer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './scrollToTop';
import './styles/styles.scss';

function App() {
  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <AppContainer>
          <Header />
          <main>
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/technology" element={<Technology />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/about" element={<About />} />
              <Route path="/vacancy" element={<Vacancy />} />
              <Route path="/stoimost" element={<Stoimost />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/cookie" element={<Cookie />} />
              <Route path="/term-of-use" element={<TermOfUse />} />
              <Route path="/projects">
                <Route index element={<Projects />} />
                <Route path="casino" element={<Casino />} />
                <Route path="bison" element={<Bison />} />
                <Route path="sportbet" element={<SportBet />} />
                <Route path="fin-crm" element={<Fincrm />} />
                <Route path="ostapilet" element={<Ostapilet />} />
                <Route path="finbet" element={<Finbet />} />
                <Route path="bfc" element={<Bfc />} />
                <Route path="dota" element={<Dota />} />
                <Route path="alfa" element={<Alfa />} />
              </Route>
            </Routes>
          </main>
          <Footer />
        </AppContainer>
      </Router>
    </div>
  );
}

export default App;
