export const HEADER_MENU_ITEMS = [
  {
    id: 0,
    name: 'header.technology',
    href: '/technology',
  },
  {
    id: 1,
    name: 'header.projects',
    href: '/projects',
  },
  // {
  //   id: 2,
  //   name: 'header.about',
  //   href: '/about',
  // },
  // {
  //   id: 3,
  //   name: 'header.blog',
  // },
  {
    id: 4,
    name: 'header.vacancy',
    href: '/vacancy',
  },
  {
    id: 5,
    name: 'header.contacts',
    href: '/contacts',
  },
  {
    id: 6,
    name: 'header.stoimost',
    href: '/stoimost',
  },
];
